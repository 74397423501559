import { schema } from 'normalizr';

/**
 * GENERAL
 */
export const APP_NAME = 'Happy Ananas';
export const TOKEN_EXPIRATION_PERIOD = process.env.REACT_APP_TOKEN_EXPIRATION_PERIOD ||
	10;
export const ITEMS_PER_PAGE = 10;

// Local storage
export const LSTORAGE_FIELDS = {
	FIELD_AUTH_TOKEN: 'auth_token',
	FIELD_AUTH_TOKEN_EXPIRED: 'auth_token_expired',
	FIELD_AUTH_USER: 'auth_manager',
};

// Paths
export const PATHS = {
	SIGNIN: '/sign-in',
	MEETINGS: '/meetings',
	MEETING_MESSAGES: '/meetings/messages',
	PARTICIPANTS: '/participants',
	RESTAURANTS: '/restaurants',
	AGE_GROUPS: '/age-groups',
	MESSAGES_TYPES: '/messages-types',
	MESSAGES_EVENTS: '/messages-events',
	MEETING_CYCLE: '/meeting-cycle',
	API_UNAVAILABLE: '/api-error',
	CAMPAIGNS: '/campaigns',
	EMAIL_TEMPLATES: '/email-templates'

};

export const HOME_PATH = PATHS.MEETINGS;

// Formats
export const DATEPICKER_DATETIME_FORMAT = 'dd.MM.yyyy EEEEE HH:mm';
export const DATEPICKER_DATE_FORMAT = 'dd.MM.yyyy';
export const DATETIME_FORMAT = 'DD.MM.YYYY ddd HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';

// Modal Form
export const MODAL_MEETING_MANAGE = 'meeting_manage';
export const MODAL_PARTICIPANT_MANAGE = 'participant_manage';
export const MODAL_RESTAURANTS_MANAGE = 'restaurant_manage';
export const MODAL_AGE_GROUPS_MANAGE = 'age_groups_manage';
export const MODAL_MESSAGES_TYPES_MANAGE = 'messages_types_manage';
export const MODAL_MESSAGES_EVENTS_MANAGE = 'messages_events_manage';
export const MODAL_MEETING_MANUALLY_MESSAGE_MANAGE = 'messages_manually_message_manage';
export const MODAL_MEETING_RESTAURANT_MANUALLY_MESSAGE_MANAGE = 'messages_manually_restaurant_message_manage';
export const MODAL_EMAIL_TEMPLATES_MANAGE = 'email_templates_manage';

export const MODALS = {
	[MODAL_MEETING_MANAGE]: require(
		'../modules/meetings/MeetingFormTabs').default,
	[MODAL_MEETING_MANUALLY_MESSAGE_MANAGE]: require(
		'../modules/meetings/components/MeetingManuallySendForm').default,
	[MODAL_MEETING_RESTAURANT_MANUALLY_MESSAGE_MANAGE]: require(
		'../modules/meetings/components/MeetingManuallyRestaurantSendForm').default,
	[MODAL_PARTICIPANT_MANAGE]: require(
		'../modules/participants/ParticipantsFormTabs').default,
	[MODAL_RESTAURANTS_MANAGE]: require(
		'../modules/restaurants/RestaurantForm').default,
	[MODAL_AGE_GROUPS_MANAGE]: require(
		'../modules/settings/AgeGroups/AgeGroupForm').default,
	[MODAL_MESSAGES_TYPES_MANAGE]: require(
		'../modules/messages-types/MessagesTypesForm').default,
	[MODAL_MESSAGES_EVENTS_MANAGE]: require(
		'../modules/messages-events/MessagesEventsForm').default,
	[MODAL_EMAIL_TEMPLATES_MANAGE]: require(
		'../modules/email-templates/EmailTemplatesForm').default,
};

// Settings
export const SETTING_FIELDS = {
	MEETING_CYCLE: 'meeting_cycle',
};

/**
 * PLUGINS CONFIGURATIONS
 */
export const TOAST_SETTINGS = {
	rtl: true,
	pauseOnHover: true,
	hideProgressBar: true,
	autoClose: 10000,
	closeOnClick: true,
	position: 'bottom-left',
};

/**
 * API
 */
export const API_BASE = process.env.REACT_APP_API_HOSTNAME || '';

export const ENDPOINTS = {
	SIGN_IN: API_BASE + '/login/',
	SIGN_OUT: API_BASE + '/logout/',

	MEETINGS: API_BASE + '/meetings/',
	MEETING: API_BASE + '/meetings/:id/',
	MEETING_PARTICIPANTS: API_BASE + '/meetings/:id/meeting_participants/',
	MEETING_PARTICIPANT: API_BASE + '/meeting_participants/:id/',
	MEETING_PARTICIPANT_CREATE: API_BASE + '/meeting_participants/',
	MEETING_TASKS: API_BASE + '/meetings/:id/tasks/',
	MEETING_MESSAGES: API_BASE + '/meetings/:id/messages/',
	SEND_ACTUAL_MESSAGES: API_BASE + '/actual_messages/:id/send/',
	MEETING_MESSAGES_MANUALLY: API_BASE + '/meetings/:id/bulk_create_and_send/',
	MEETING_CREATE_SMS_FILL_UP_GROUP: API_BASE + '/meetings/:id/create_sms_fill_up_group/',
	MEETING_SEND_SMS_FILL_UP_MEN: API_BASE + '/meetings/:id/send_sms_fill_up/:arrow/Male/',
	MEETING_SEND_SMS_FILL_UP_WOMEN: API_BASE + '/meetings/:id/send_sms_fill_up/:arrow/Female/',
	MEETING_CANCEL: API_BASE + '/meetings/:id/cancel/',
	PARTICIPANT_MESSAGE_MANUALLY: API_BASE + '/bulk_create_and_send/',

	DUPLICATE_CURRENT_WEEK: API_BASE + '/duplicate_current_week/',
	DUPLICATE_LAST_MEETING_WEEK: API_BASE + '/duplicate_last_meeting_week/',
	MEETING_DUPLICATE_WEEK: API_BASE + '/meetings/:id/duplicate_week/',
	MEETING_UNDRAFT: API_BASE + '/meetings/:id/undraft/',
	MEETING_DUPLICATE: API_BASE + '/meetings/:id/duplicate/',
	MEETING_CREATE_WHATSAPP_GROUPS: API_BASE + '/meetings/:id/create_whatsapp_group/',

	PARTICIPANTS: API_BASE + '/participants/',
	PARTICIPANT: API_BASE + '/participants/:id/',
	PARTICIPANT_CONTACTS: API_BASE + '/participants/:id/contacts/',
	PARTICIPANT_RESTAURANTS: API_BASE + '/participants/:id/restaurants/',
	PARTICIPANT_MESSAGES: API_BASE + '/participants/:id/messages/',
	PARTICIPANT_EVENTS: API_BASE + '/participants/:id/participation_events/',
	// TODO: fix the typo since the backend is completed

	RESTAURANTS: API_BASE + '/restaurants/',
	RESTAURANT: API_BASE + '/restaurants/:id/',

	AGE_RNAGES: API_BASE + '/age_ranges/',

	CITIES: API_BASE + '/cities/',

	CLUBS: API_BASE + '/clubs',

	AGE_GROUPS: API_BASE + '/age_groups/',
	AGE_GROUP: API_BASE + '/age_groups/:id/',
	AGE_GROUP_UPDATE_DAILY_GROUP: API_BASE + '/age_groups/:id/update_daily_group/',
	AGE_GROUP_SEND_CAMPAIGN: API_BASE + '/age_groups/:id/send_campaign/',
	AGE_GROUP_SEND_CAMPAIGN_MEN: API_BASE + '/age_groups/:id/send_campaign/:emailTemplate/Male/',
	AGE_GROUP_SEND_CAMPAIGN_WOMEN: API_BASE + '/age_groups/:id/send_campaign/:emailTemplate/Female/',
	AGE_GROUP_SEND_CAMPAIGN_MESSAGE_MEN: API_BASE + '/age_groups/:id/send_campaign_with_followup/:emailTemplate/:messageTemplate/Male/',
	AGE_GROUP_SEND_CAMPAIGN_MESSAGE_WOMEN: API_BASE + '/age_groups/:id/send_campaign_with_followup/:emailTemplate/:messageTemplate/Female/',
	AGE_GROUP_SEND_CAMPAIGN_MEN_CLUB: API_BASE + '/age_groups/:id/send_club_campaign/:emailTemplate/Male/:club/',
	AGE_GROUP_SEND_CAMPAIGN_WOMEN_CLUB: API_BASE + '/age_groups/:id/send_club_campaign/:emailTemplate/Female/:club/',
	AGE_GROUP_SEND_CAMPAIGN_MESSAGE_MEN_CLUB: API_BASE + '/age_groups/:id/send_club_campaign_with_followup/:emailTemplate/:messageTemplate/Male/:club/',
	AGE_GROUP_SEND_CAMPAIGN_MESSAGE_WOMEN_CLUB: API_BASE + '/age_groups/:id/send_club_campaign_with_followup/:emailTemplate/:messageTemplate/Female/:club/',

	SETTINGS: API_BASE + '/meeting_lifecycle/',
	SETTING: API_BASE + '/meeting_lifecycle/:id/',

	MESSAGES_EVENTS: API_BASE + '/sending_events/',
	MESSAGES_EVENT: API_BASE + '/sending_events/:id/',

	MESSAGES_TYPES: API_BASE + '/message_templates/',
	MESSAGES_TYPE: API_BASE + '/message_templates/:id/',

	EMAIL_TEMPLATES: API_BASE + '/email_message_templates/',
	EMAIL_TEMPLATE: API_BASE + '/email_message_templates/:id/',
};

export const RESPONSE_DATA_FIELD = 'results';

/**
 * ENTITIES
 */
export const ENTITY_MEETINGS = 'meetings';
export const ENTITY_PARTICIPANTS = 'participants';
export const ENTITY_RESTAURANTS = 'restaurants';
export const ENTITY_AGE_GROUPS = 'ageGroups';
export const ENTITY_AGE_RANGES = 'ageRanges';
export const ENTITY_CITIES = 'cities';
export const ENTITY_CLUBS = 'clubs';
export const ENTITY_MESSAGE_EVENTS = 'messagesEvents';
export const ENTITY_MESSAGE_TYPES = 'messagesTypes';
export const ENTITY_EMAIL_TEMPLATES = 'emailTemplates';
export const ENTITY_MEETINGS_MESSAGES = 'meetingsMessages';
export const ENTITY_PARTICIPANT_MESSAGES = 'participantMessages';
export const ENTITY_PARTICIPANT_EVENTS = 'participantEvents';

export const PARTICIPATION_STATUS_REGISTERED = 0;
export const PARTICIPATION_STATUS_CONFIRMED = 1;
export const PARTICIPATION_STATUS_ARRIVED = 2;
export const PARTICIPATION_STATUS_CANCELLED = 3;
export const PARTICIPATION_STATUS_CANCELLED_BY_SYS = 4;
export const PARTICIPATION_STATUS_WAITING = 5;
export const PARTICIPATION_STATUS_ANSWERED_QUESTIONNAIRE = 6;
export const PARTICIPATION_STATUS_CONFIRMED_CANCELLED_BY_SYS = 7;

export const PARTICIPATION_STATUSES = {
	[PARTICIPATION_STATUS_REGISTERED]: 'registered',
	[PARTICIPATION_STATUS_CONFIRMED]: 'confirmed',
	[PARTICIPATION_STATUS_ARRIVED]: 'arrived',
	[PARTICIPATION_STATUS_CANCELLED]: 'cancelled',
	[PARTICIPATION_STATUS_CANCELLED_BY_SYS]: 'cancelled_by_system',
	[PARTICIPATION_STATUS_WAITING]: 'waiting',
	[PARTICIPATION_STATUS_ANSWERED_QUESTIONNAIRE]: 'answered_questionnaire',
	[PARTICIPATION_STATUS_CONFIRMED_CANCELLED_BY_SYS]: 'confirmed_cancelled_by_system',
};

export const PAYMENT_STATUSES = {
	[true]: 'successful',
	[false]: 'failed'
};

export const WEEKDAY_SUNDAY = 1;
export const WEEKDAY_MONDAY = 2;
export const WEEKDAY_TUESDAY = 3;
export const WEEKDAY_WEDNESDAY = 4;
export const WEEKDAY_THURSDAY = 5;
export const WEEKDAY_FRIDAY = 6;
export const WEEKDAY_SATURDAY = 7;

export const WEEKDAYS = {
	[WEEKDAY_SUNDAY]: 'sunday',
	[WEEKDAY_MONDAY]: 'monday',
	[WEEKDAY_TUESDAY]: 'tuesday',
	[WEEKDAY_WEDNESDAY]: 'wednesday',
	[WEEKDAY_THURSDAY]: 'thursday',
	[WEEKDAY_FRIDAY]: 'friday',
	[WEEKDAY_SATURDAY]: 'saturday',
};

export const SEND_DAYS_BEFORE_OR_AFTER = 0;
export const SEND_WEEKDAY = 1;

export const MESSAGE_EVENTS_WHEN_SEND = {
	[SEND_DAYS_BEFORE_OR_AFTER]: 'send_days_before_or_after',
	[SEND_WEEKDAY]: 'send_certain_weekday',
};

export const PARTICIPATION_STATUS_FILTERS = {
	'actived': [
		PARTICIPATION_STATUS_REGISTERED,
		PARTICIPATION_STATUS_CONFIRMED,
		PARTICIPATION_STATUS_ARRIVED,
		PARTICIPATION_STATUS_ANSWERED_QUESTIONNAIRE,
	],
	'waiting': [
		PARTICIPATION_STATUS_WAITING,
	],
	'cancelled': [
		PARTICIPATION_STATUS_CONFIRMED_CANCELLED_BY_SYS,
		PARTICIPATION_STATUS_CANCELLED_BY_SYS,
		PARTICIPATION_STATUS_CANCELLED,
	],
};

export const MESSAGES_EVENT_FORM_FILTERS = [
	'time_regarding_meeting',
	'participant_status_changed',
];

export const MESSAGES_EVENTS_TYPES = [
	'MeetingStatusBasedSendingEvent',
	'ParticipantStatusBasedSendingEvent',
];

export const GENDER_WOMAN = 0;
export const GENDER_MAN = 1;

export const TASK_STATUS_ACCEPTED = 0;
export const TASK_STATUS_COMPLETED = 1;
export const TASK_STATUS_SKIPPED = 2;

export const TASK_STATUSES = {
	[TASK_STATUS_ACCEPTED]: 'accepted',
	[TASK_STATUS_COMPLETED]: 'completed',
	[TASK_STATUS_SKIPPED]: 'skipped',
};

export const EVENT_TYPE_PAYMENT = 'payment';
export const EVENT_TYPE_PARTICIPATION = 'participation';
export const EVENT_TYPE_GENERAL = 'general';
export const EVENT_TYPE_SMS_FILL_UP = 'sms fill up';

export const EVENT_TYPES = [
	EVENT_TYPE_PARTICIPATION,
	EVENT_TYPE_GENERAL,
];

export const RESTAURANT_FOOD_NO_COSHER = 0;
export const RESTAURANT_FOOD_COSHER = 1;
export const RESTAURANT_FOOD_COSHER_STYLE = 2;

export const RESTAURANT_FOOD_TYPES = {
	[RESTAURANT_FOOD_NO_COSHER]: 'no_cosher',
	[RESTAURANT_FOOD_COSHER]: 'cosher',
	[RESTAURANT_FOOD_COSHER_STYLE]: 'cosher_style',
};

export const MEETING_TYPE_REGULAR = 1;
export const MEETING_TYPE_WOMEN_ONLY = 2;
export const MEETING_TYPE_TRAVEL_ABROAD = 3;
export const MEETING_TYPE_TRAVEL_LOCAL = 4;
export const MEETING_TYPE_TRAVEL_MARKET = 5;

export const MEETING_TYPES = {
	[MEETING_TYPE_REGULAR]: 'regular',
	[MEETING_TYPE_WOMEN_ONLY]: 'women_only',
	[MEETING_TYPE_TRAVEL_ABROAD]: 'travel_abroad',
	[MEETING_TYPE_TRAVEL_LOCAL]: 'travel_local',
	[MEETING_TYPE_TRAVEL_MARKET]: 'travel_market'
};

export const MEETING_DAY_PART_MORNING = 1;
export const MEETING_DAY_PART_NOON = 2;
export const MEETING_DAY_PART_EVENING = 3;

export const MEETING_DAY_PARTS = {
	[MEETING_DAY_PART_MORNING]: 'morning',
	[MEETING_DAY_PART_NOON]: 'noon',
	[MEETING_DAY_PART_EVENING]: 'evening',
};

export const MEETING_SCHEDULE_TYPE_PLANNED = 1;
export const MEETING_SCHEDULE_TYPE_SPONTANEOUS = 2;

export const MEETING_SCHEDULE_TYPES = {
	[MEETING_SCHEDULE_TYPE_PLANNED]: 'planned',
	[MEETING_SCHEDULE_TYPE_SPONTANEOUS]: 'spontaneous',
};

/**
 * NORMALZR SCHEMAS
 */
export const meeting = new schema.Entity(ENTITY_MEETINGS);
export const meetings = [meeting];

export const restaurant = new schema.Entity(ENTITY_RESTAURANTS);
export const restaurants = [restaurant];

export const city = new schema.Entity(ENTITY_CITIES);
export const cities = [city];

export const participant = new schema.Entity(ENTITY_PARTICIPANTS);
export const participants = [participant];

export const messagesType = new schema.Entity(ENTITY_MESSAGE_TYPES);
export const messagesTypes = [messagesType];

export const messagesEvent = new schema.Entity(ENTITY_MESSAGE_EVENTS);
export const messagesEvents = [messagesEvent];

export const meetingsMessage = new schema.Entity(ENTITY_MEETINGS_MESSAGES);
export const meetingsMessages = [meetingsMessage];

export const participantMessage = new schema.Entity(
	ENTITY_PARTICIPANT_MESSAGES);
export const participantMessages = [participantMessage];

export const emailTemplate = new schema.Entity(ENTITY_EMAIL_TEMPLATES);
export const emailTemplates = [emailTemplate];

export const SCHEMAS = {
	meeting,
	meetings,
	restaurants,
	restaurant,
	city,
	cities,
	participants,
	participant,
	messagesType,
	messagesTypes,
	messagesEvent,
	messagesEvents,
	meetingsMessage,
	meetingsMessages,
	participantMessage,
	participantMessages,
	emailTemplate,
	emailTemplates
};

/**
 * WYSIWYG SETTINGS
 */
export const WYSIWYG_SETTINGS_FORMATS = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'color',
	'emoji',
];

export const DEFAULT_TEMPLATE_TAGS = {
	'First name': '{{FIRST_NAME}}',
	'Last name': '{{LAST_NAME}}',
	'Greeting': '{{GREETING}}',
	'Meeting date': '{{MEETING_DATE}}',
	'Meeting day': '{{MEETING_DAY}}',
	'Meeting day Name': '{{MEETING_DAY_NAME}}',
	'Meeting day part Name': '{{MEETING_DAY_PART_NAME}}',
	'Meeting hour': '{{MEETING_HOUR}}',
	'Restaurant': '{{RESTAURANT}}',
	'Address': '{{ADDRESS}}',
	'Confirmation link': '{{CONFIRMATION_URL}}',
	'Waze link': '{{WAZE_LINK}}',
	'Directions': '{{DIRECTIONS}}',
	'Parking waze link': '{{PARKING_WAZE_LINK}}',
	'Parking directions': '{{PARKING_DIRECTIONS}}',
	'Questionnaire url': '{{QUESTIONNAIRE_URL}}',
	'Event registration url': '{{EVENT_REGISTRATION_URL}}',
	'Event details url': '{{EVENT_DETAILS_URL}}',
	'Whatsapp group url': '{{WHATSAPP_GROUP_URL}}',
	'Sysyem cancel reason': '{{SYS_CANCEL_REASON}}',
};

export const PARTICIPANT_MESSAGE_TEMPLATE_TAGS = {
	'First name': '{{FIRST_NAME}}',
	'Last name': '{{LAST_NAME}}',
	'Link ID': '{{LINK_ID}}',
	'Index URL': '{{INDEX_URL}}',
	'Greeting': '{{GREETING}}',
};

export const WHEN_MEETING_TYPES = {
	all: 'all',
	future: 'future',
	past: 'past',
};
